import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MainBurger from "../components/MainBurger"

export default function Burgers() {
  const data = useStaticQuery(graphql`
    {
      allWpBurger {
        nodes {
          info {
            description
            extraInfo
            price
            vegPrice
            doublePrice
          }
          title
        }
      }
    }
  `)
  const posts = data.allWpBurger.nodes

  return (
    <div className="table w-full">
      <div className="table-row-group ">
        <div className="md:table-row w-full pb-8 font-bold hidden">
          <div className="table-cell"></div>
          <div className="table-cell">Single</div>
          <div className="table-cell">Double</div>
          {/* <div className="table-cell">Naturli*</div> */}
        </div>

        <div className="md:table-row hidden w-full pb-8">
          <div className="table-cell font-bold"></div>
          <div className="table-cell">150g</div>
          <div className="table-cell">2x110g</div>
          {/* <div className="table-cell">150g</div> */}
        </div>

        {posts.map(post => {
          return <MainBurger post={post} key={post.id} />
        })}
      </div>
    </div>
  )
}
