import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Ratt from "./Ratt";

export default function Falafel() {
  const data = useStaticQuery(graphql`
    {
      allWpFalafel {
        nodes {
          info {
            description
            extraInfo
            price
          }
          title
        }
      }
    }
  `);
  const posts = data.allWpFalafel.nodes;

  return (
    <div className="grid md:grid-cols-2 gap-4">
      {posts.map(post => {
        return <Ratt post={post} key={post.id} />;
      })}
    </div>
  );
}
