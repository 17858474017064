import React from "react";

function Ratt({ post }) {
  const { title } = post;
  const { description, extraInfo, price } = post.info;
  return (
    <div className="flex flex-col justify-start md:my-0 text-md space-y-2 md:pr-2">
      <header className="flex flex-row justify-between w-full  items-baseline">
        <h4 className="food-title  uppercase font-bold">{title}</h4>
        <p className="text-pink-500">{price && price + "kr"}</p>
      </header>

      <div className="">
        <p className="">{description}</p>
      </div>
      <div className="text-xs font-italic text-pink-500">{extraInfo}</div>
    </div>
  );
}

export default Ratt;
