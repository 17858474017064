import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Burgers from "../components/Burgers"
import Falafel from "../components/Falafel"
import Sides from "../components/Sides"
import Fade from "react-reveal"
import { Link } from "gatsby"
const IndexPage = () => (
  <Layout isHome>
    <Seo title="Falafel &amp; Burgers" isHome />

    <section
      id="hamburgare"
      className="max-w-6xl mx-auto px-4 md:px-8 py-8 space-y-8 mt-4"
    >
      <p className="text-center pb-4">
        Luncherbjudande kl 11.00-14.00
        <br />
       Classic burger / Halloum Burger + pommes 125kr
        <br />
        <br />
        100% nötkött från skånska gårdar
        <br />
        <br />
        <div className="py-8 max-w-xl mx-auto">
          <Link to="https://weiq.tech/gapp?seller=e69d42bb-d06d-4b41-ae8f-0a0cfe89b0a0">
            <StaticImage
              src="../images/falafel-burgers-weiq-badge-take-away.png"
              alt="Beställ"
            />
          </Link>
        </div>
      </p>
      <h3 className="text-center uppercase text-4xl font-bold pb-4">Burgers</h3>

      <p className="text-center"></p>
      <Fade bottom distance="100px">
        <Burgers />
      </Fade>
    </section>
    <Fade>
      <section className="text-center py-8 space-y-4">
        <h4 className="text-4xl">"Malmös bästa hamburgare"</h4>
        <p>
          Enligt{" "}
          <a
            className="underline"
            href="https://www.burgerdudes.se/sweden/malmo/falafel-burgers/"
          >
            burgerdudes.se
          </a>
        </p>
      </section>
    </Fade>
    <Fade>
      <section className="px-4 md:px-8 mt-8 mb-16">
        <StaticImage
          src="../images/devimages/5.jpg"
          alt="Falafel &amp; Burgers"
          className="w-full h-full object-cover overflow-hidden"
          quality="80"
        />
      </section>
    </Fade>
    <Fade>
      <section
        id="falafel"
        className="max-w-6xl mx-auto px-4 md:px-8 space-y-8"
      >
        <h3 className="text-center uppercase text-4xl font-bold">Falafel</h3>
        <Falafel />
      </section>
    </Fade>
    <Fade>
      <section
        id="sides"
        className="max-w-2xl mx-auto px-4 md:px-8 space-y-8 mt-8"
      >
        <h3 className="text-center uppercase text-4xl font-bold">&amp;</h3>
        <Sides />
      </section>
    </Fade>
    <section className=" px-4 md:px-8 mt-8">
      <StaticImage
        src="../images/devimages/6.jpg"
        alt="Falafel &amp; Burgers"
        className="w-full h-full object-cover overflow-hidden"
        quality="80"
      />
    </section>
  </Layout>
)

export default IndexPage
